.block {
  margin-bottom: 46px;
}

.document {
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  padding: 85px 200px 120px;
}

.document__header {
  text-align: center;
  margin-bottom: 40px;
}
.typography {
  line-height: 1.625;
}

.document__content {
  text-align: left;
}

.document__content h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  margin-top: 1.75em;
  margin-bottom: 0.75em;
}
.document__content p:first-child,
.ol li,
.document__content .p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625;
  color: #3d464d;
  text-align: left;
  direction: ltr;
  margin-bottom: 1.5em;
  margin-top: 0;
}
.document .document__header .document__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  margin-top: 1.75em;
  margin-bottom: 0.5rem;
}

.imgAboutUsBanner {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.imgAboutUsBanner + .document {
  padding-top: 0px;
  padding-bottom: 0px;
}

.imgAboutUsBanner + .document .document__header .document__title {
  margin-top: 20px;
}
p + .divOfferCardsContainer .block-features__list {
  flex-wrap: wrap;
}
p + .divOfferCardsContainer .block-features__list .block-features__item {
  display: flex;
  flex-direction: row !important;
  column-gap: 30px;
  background-color: #fff;
  border-color: #f7f7f7;
  width: 35%;
  height: 120px;
  text-align: center;
  border: 3px solid #f7f7f7;
  margin: 0px 10px 10px 0px;
  text-align: center;
  align-items: center;
  justify-content: left;
  padding-left: 10%;
}

p
  + .divOfferCardsContainer
  .block-features__list
  .block-features__item
  .block-features__content {
  text-align: left;
}

p
  + .divOfferCardsContainer
  .block-features__list
  .block-features__item
  .block-features__content
  .block-features__title {
  font-size: 22px !important;
}

@media (max-width: 991px) {
  .document {
    padding: 85px 100px 120px;
  }
  .document__header {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .document {
    padding: 85px 20px 120px;
  }
}
