.faqIconContainerMobile{
    width: 100%;
    display: flex;
    flex: row;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
}

.faqIconListContainerMobile .block-features__item{
    width: 100%;
}

.faqIconContainerMobile img{
    padding-right: 0px;
}

.faqIconContainerMobile .block-features__content .faqCardTitle{
    justify-content: center;
    font-size: 19px;
    width: 220px;
    margin-top: 3px;
}

.faqIconListContainerMobile .divHiddenFaqPage .faqItemCollapseContainer:last-child{
    margin-bottom: 30px;
}


@media only screen and (max-width: 560px) {

    .faqIconContainerMobile .block-features__content .faqCardTitle{
        font-size: 16px;
        width: 112px;
        margin-top: 3px;
    }
}

@media only screen and (min-width:560px) and (max-width: 750px) {

    .faqIconContainerMobile .block-features__content .faqCardTitle{
        font-size: 16px;
    }
}