/* styles.css */
.not-found-container {
    text-align: center;
    padding: 40px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 54.1vh;
  }
  
  button {
    /* background-color: #007bff; */
    /* color: #fff; */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  