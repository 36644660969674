.block {
  margin-bottom: 46px;
}

.faq__section + .faq__section {
  margin-top: 70px;
}

.faq__section-title {
  margin-bottom: 20px;
}
.faq__section-title h3 {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.typography {
  line-height: 1.625;
}

h6 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
}
h1 {
  margin-top: 50px;
}

.faqIconListContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start !important;
  column-gap: 14px;
  padding: 0px;
}

.block-features__item {
  box-sizing: border-box;
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item{
  border-bottom: 1px solid #d9d9d9 !important;
}

.block-features__item:hover .faqCardTitle {
  color: #004D8C !important;
}

.faqIconContainer {
  box-sizing: border-box;
  border: 1px solid #F0F0F0;
}

.faqIconContainer:hover {
  border: 1px solid #004D8C;
  color: #004D8C;
}

.faqIconContainer img {
  background-color: transparent;
  height: 90px;
  width: 120px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
  object-fit: cover;
}

.faqCardTitle {
  font-size: large;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  text-transform: uppercase;
  width: 150px;
}
.faqItemCollapseContainer {
  margin-top: 8px;
}

.faqItemCollapseContainer:last-child {
  margin-bottom: 100px;
}
.faqItemCollapseContainer .ant-collapse-header-text {
  text-align: left !important;
  padding-left: 5px !important;
}
.faqItemCollapseContainer .ant-collapse,
.faqItemCollapseContainer .ant-collapse .ant-collapse-item {
  border-radius: 0px !important;
  background-color: #ffffff;
  box-shadow: 0px 1px 1px 1px rgb(228, 227, 227);
}

.faqItemCollapseContainer .ant-collapse .ant-collapse-item .ant-collapse-header{
  padding-left: 10px;
}

.faqItemCollapseContainer
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box
  p {
  padding: 0px 24px;
}
.faqdocument {
  padding: 0px 30px 85px 30px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.faqCardPanelContainer {
  width: 80%;
}

.faqCardPanelContainerMobile{
  width: 100%;
}

.divHiddenFaqPage {
  width: 100%;
  text-align: center;
  align-items: center;
  margin: auto;
}

.ant-collapse-header-text {
  font-weight: bold;
  font-size: 16px;
}

.ant-collapse-content-box {
  font-size: 16px;
}

@media (max-width: 991px) {
  .faqIconListContainer {
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .faqIconContainer {
    text-align: center;
  }

  .faqCardTitle {
    margin: 0 auto;
  }

  .faqdocument {
    padding: 0px 0px 20px 0px !important;
  }

  .faqIconContainer {
    width: fit-content;
    margin: 15px auto;
  }

  .faqIconContainer img {
    padding: 0;
  }
}
