.block-products-carousel__cell .product-card {
  width: 100%;
}
.product-card {
  position: relative;
  padding: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-color: #e2e2e2 !important;
  width: 300px;
  height: 376px;
  margin: 0px 10px 10px 0px;
  border: 2px solid;
  z-index: 10;
}
.product-card:hover {
  z-index: 100;
  border-color: #004d8c !important;
  border-width: 2px !important;
  border-style: solid;
  max-width: 300px;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.product-card__quickview {
  z-index: 2;
  position: absolute;
  border: none;
  font-size: 10px;
  text-transform: uppercase;
  fill: currentColor;
  padding: 7px;
  cursor: pointer;
  top: 0;
  background: transparent;
  color: #ccc;
  transition: background-color 0.2s, color 0.2s;
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 0;
}

@media (min-width: 375px) {
  .block-products-carousel[data-layout='grid-4-sm'][data-mobile-grid-columns='2']
    .product-card
    .product-card__badges-list,
  .block-products-carousel[data-layout='grid-5'][data-mobile-grid-columns='2']
    .product-card
    .product-card__badges-list {
    top: 16px;
    left: 16px;
  }
}
.product-card__badges-list {
  top: 18px;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  z-index: 1;
  left: 12px;
}
.product-card__badges-listBOTH{
top: 40px;
}

.product-card__badge--new {
  background: #F07C00;
  color: #fff;
}

.product-card__badge--hot {
  background: #00AEEF ;
  color: #fff;
}
.product-card__badge--sale {
  background: #f33;
  color: #fff;
}
.product-card__badge {
  font-size: 11px;
  border-radius: 1.5px;
  letter-spacing: 0.02em;
  line-height: 1;
  padding: 5px 8px 4px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-right: 4px;
}

/* padding based on min width todo */
.product-card__image {
  /* padding: 15px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 8px; */
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.product-image__body {
  display: block;
  position: relative;
  /* padding-bottom: 100%; */
  width: 100%;
}
a:hover {
  color: #00AEEF;
}
a {
  /* color: #3d464d; */
  color: #004D8C;
  text-decoration: none;
  background-color: transparent;
}

.product-image__img {
  display: block;
  position: relative;
  width: 200px;
  height: 180px;
  /* -o-object-fit: scale-down;
  object-fit: scale-down; */ 
  /* left: 5px; */
  top: 0px;
}
img {
  vertical-align: middle;
  border-style: none;
}

.product-card__info {
  padding: 2px 12px 24px;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 50px;
}

.product-card__name {
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
  width: 200px;
  align-self: flex-start;
}

.product-card__name a {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
}

.product-card__name p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-box-orient: horizontal; */
  font-size: 12px;
  -webkit-line-clamp: 4;
  /* line-height: 19px; */
  font-weight: 500;
  height: 115px;
}

.product-card__code {
  font-size: 13px;
  line-height: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
  width: 100%;
  align-self: flex-start;
  color: #6c757d;
  margin-bottom: 5px;
}

.product-card__rating {
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap; */
  /* flex-wrap: wrap; */
  /* justify-content: space-evenly; */
  height: 25px;
  /* width: 150px; */
}
.product-card__rating-legend {
  padding-top: 3px;
  padding-left: 10px;
  font-size: 13px;
  line-height: 1;
  color: #b3b3b3;
  /* padding-top: 1px; */
  white-space: nowrap;
}

.product-card__prices {
  margin-top: 14px;
  line-height: 1;
  font-weight: 600;
  color: #3d464d;
  align-content: flex-start;
  display: flex;
  margin-left: 10px;
}

.product-card__features-list {
  list-style: none;
  margin: 10px 0 6px;
  padding: 0;
  color: #6c757d;
  font-size: 13px;
  line-height: 15px;
}
.product-card--hidden-actions .product-card__buttons {
  /* display: none; */
}

.product-card__buttons {
  display: -ms-flexbox;
  display: flex;
  /* margin-top: 24px;
    margin-left: 10px; */
  margin: 24px 10px 10px 10px;
}

.product-card__buttons .btn {
  font-size: 0.8125rem;
  height: calc(1.5rem + 2px);
  line-height: 1.25;
  padding: 0.25rem 0.5625rem;
  font-weight: 500;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.product-card__buttons > :first-child {
  margin-right: auto;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #00aeef;
  background: #00aeef;
  color: #fff;
  fill: #3d464d;
}
.btn {
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 500;
  height: 28px;
  line-height: 1.5;
  padding: 0.375rem 1.25rem;
  transition: all 0.1s ease-in-out;
  width: 109px;

  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}
.btn-primary:hover {
  color: #fff;
  background-color: #3d464d;
  border-color: #3d464d;
}




.atagBlogTitle text:hover{
  background-color: #00aeef !important;
  color: white !important;
}
