.divOfferCardsContainer {
  display: flex;
  flex-direction: row;
}
.divOfferCardsContainer .block-features__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  width: 100%;
}

.divOfferCardsContainer .block-features__list .block-features__item{
  cursor: default !important;
}

.divHome .ant-carousel {
  margin-bottom: 10px;
}
.divHome {
  background-color: white !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 50px;
  /* margin-top: 20px; */
}

.imgCarousel {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 90%;
  size: cover;
}

.block-slideshow__slide-content {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  margin-left: 50px;
}
.block-slideshow__slide-title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 12px;
  opacity: 1;
  transition: all 1s 0.2s;
  color: #3d464d;
  text-align: left;
}
.block-slideshow__slide-text {
  line-height: 1.625;
  opacity: 1;
  transform: translateY(15px);
  transition: all 0.8s 0.5s;
  margin-left: 20px;
  margin-bottom: 20px;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #3d464d;
  text-align: left;
  direction: ltr;
}

.block-slideshow__slide-button {
  align-items: flex-start;
  text-align: left;
  margin-left: 20px;
  margin-top: 60px;
}
.block-slideshow__slide-button a:hover{
background-color: #004D8C !important;
}
.ant-carousel .slick-dots li.slick-active button{
  background-color: #004D8C !important;
}

.ant-carousel .slick-dots li{
  display: flex !important;
  text-align: center !important;
  align-items: center !important;
  width: 50px !important;
  height: 15px !important
}
.ant-carousel .slick-dots li button{
  height: 13px !important;
}

.divHome .block-features__item {
  flex-direction: column;
  /* border: none !important; */
  border-radius: 3px !important;
  text-align: center;
  align-items: center;
  padding: 30px 28px 28px;
  border: 2px solid #f7f7f7;
  margin: 0px 10px 10px 0px;
  width: 250px;
  height: 130px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.divHome .block-features__item:last-child {
  margin-right: 0px;
}

.divHome .block-features__item {
  flex-basis: 0;
  flex-grow: 1;
  padding: 16px 24px;
  display: flex;
  border: 2px solid #f0f0f0;
}

.block-features__icon {
  fill: #37f;
}

.block-features__title {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  color: #3d464d;
}

.block-features__subtitle {
  font-size: 14px;
}

.divFeaturedProductsContainer {
  display: flex;
  min-height: 340px;
  flex-direction: row;
  flex-wrap: wrap;
}
.divProductScrollContainer {
  overflow-x: scroll;
  scroll-behavior: smooth;
}

#btn-left, #btn-right, #btn-left2, #btn-right2{
  width: 27px;
  height: 29px;
  padding: 0;
  margin: 0;
    margin-left: 5px;
    margin-top: 20px;
  border: none;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  fill: #999;
  border-radius: 1.5px;
  background: #f2f2f2;
  transition: all .15s;
  text-align: center;
  align-items: center;
  display: flex;
}
#btn-left:hover, #btn-right:hover, #btn-right2:hover, #btn-left2:hover{
  background: #00aeef !important;
}

.divBannerContianer {
  padding-left: 8px;
  padding-right: 8px;
}

.block-banner__body {
  position: relative;
  display: block;
  height: 170px;
  text-align: center;
  color: inherit;
}
.block-banner__body:hover {
  color: unset;
}
.block-banner__image {
  position: initial;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: -1;
}

.block-banner__title {
  padding-top: 28px;
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
}
.block-banner__text {
  padding-top: 8px;
}
.block-banner__button {
  padding-top: 20px;
}
.divHandToolsLabelContainer {
  position: relative;
  bottom: 180px;
}

.block-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.block-header__title {
  margin-bottom: 0;
  font-size: 20px;
}
.block-header__title + .block-header__divider {
  margin-left: 16px;
}
.block-header__divider {
  margin-top: 25px;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 2px;
  background: #ebebeb;
}

.block-categories__list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -6px;
}

.block-categories__item {
  margin: 2px;
  width: calc(33.3333% - 16px);
  border: 2px solid #fff;
}
.block-categories__item:hover {
  border-color: #37f;
  border-width: 2px solid;
}
.category-card,
.category-card__body {
  display: -ms-flexbox;
  display: flex;
}
.category-card,
.category-card__body {
  display: -ms-flexbox;
  display: flex;
}
.category-card__body {
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.04);
  padding: 24px 20px;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.category-card--layout--compact .category-card__body {
  padding: 16px 30px;
  -ms-flex-align: center;
  align-items: center;
}

.category-card--layout--compact .category-card__image {
  width: 74px;
  height: 74px;
  margin-right: 30px;
}
.category-card__image img {
  max-width: 100%;
  max-height: 74px;
}

.category-card__content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.category-card--layout--compact .category-card__name {
  margin-top: 0;
  font-size: 17px;
  line-height: 19px;
}
.category-card--layout--compact .category-card__products {
  display: block;
  font-size: 15px;
  color: #999;
  margin-top: 5px;
}

.category-card__links,
.category-card__all {
  display: none;
}

.divPopularCategoriesContainer {
  padding: 0px 0 10px;
}
.divPopularCategoriesContainer .container {
  max-width: 98%;
  padding: 15px 10px;
  background-color: rgb(251, 249, 249);
}

.divFlexBlogPostsContainer {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
}
.divFlexBlogPostsContainer .post-card__category,
.post-card__read-more,
.divHomeFlexBlogPostsContainer .post-card__category,
.divHomeFlexBlogPostsContainer .post-card__read-more {
  display: none;
}
.divFlexBlogPostsContainer .post-card {
  width: 33%;
  margin-right: 30px;
}
.divFlexBlogPostsContainer .post-card__image {
  align-items: flex-start;
  /* float: left; */
  align-self: flex-start;
  text-align: left;
  margin-bottom: 14px;
}
.post-card__image img {
  /* width: max-content; */
  width: -moz-available;
  width: 100%;
  height: 260px;
  width: 360px;
}

.post-card .post-card__info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.post-card .post-card__name {
  width: 100%;
  font-size: 18px;
  margin-top: 4px;
  line-height: 22px;
  text-align: left;
  direction: ltr;
}
.post-card__name {
  font-size: 20px !important;
  line-height: 1.125;
  font-weight: 700;
}
.post-card__name a {
  font-size: 20px !important;
}

.post-card .post-card__date {
  -ms-flex-order: -1;
  order: -1;
}
.post-card__date {
  font-size: 14px;
  color: #adadad;
}
.post-card .post-card__content {
  width: 100%;
  margin-top: 12px;
  text-align: left;
  direction: ltr;
  margin-bottom: 10px;
}
.post-card__content {
  font-size: 15px;
  line-height: 24px;
}

.divTopSpecialBestContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  background-color: rgb(251, 249, 249);
}
.divTopRated,
.divSpecialOffers,
.divBestSellers {
  width: 32%;
  margin-right: 10px;
}
.divTopRated .block-header .block-header__title,
.divSpecialOffers .block-header .block-header__title,
.divBestSellers .block-header .block-header__title {
  font-weight: 700 !important;
}
.divSpecialColumn .product-card {
  width: 360px !important;
  height: 110px;
  flex-direction: row;
  flex-wrap: wrap !important;
  border: 2px solid #fff !important;
}

.divSpecialColumn .product-card:hover {
  z-index: 100;
  border-color: #37f !important;
  border-width: 2px !important;
  border-style: solid;
  max-width: 360px !important;
}
.divSpecialColumn .product-image__img,
.divSpecialColumn .product-image {
  width: 78px !important;
  height: 78px;
}
.divSpecialColumn .product-card__info,
.divSpecialColumnProduct-card__name {
  width: 150px !important;
  padding-bottom: 0px !important;
  align-self: center;
}
.divSpecialColumnProduct-card__name {
  overflow: hidden;
  max-height: 45px;
  font-size: 20px;
  line-height: 19px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  text-align: left;
  color: #3d464d;
  direction: ltr;
}
.divSpecialColumn .product-card .product-card__image {
  padding-top: 20px;
}

.divSpecialColumn .product-card__actions {
  position: relative;
  left: 110px;
  bottom: 45px;
}

.slick-dots slick-dots-bottom {
  height: 20px !important;
}
.slick-dots li {
  height: 20px !important;
  width: 20px !important;
  color: #37f !important;
}

.slick-dots button {
  height: 10px !important;
  color: #37f !important;
  background: #37f !important;
}

.divCarouselWrapper {
  margin-bottom: 15px !important;
}

.slick-arrow {
  bottom: 24px !important;
  top: unset !important;
  z-index: 20 !important;
}

.slick-prev {
  left: 50% !important;
  transform: translateX(-420%) !important;
  color: #37f !important;
}

.slick-prev::before {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 30px;
  color: #37f !important;
}

.slick-next {
  right: 50% !important;
  transform: translateX(370%) !important;
  color: #37f !important;
}

.slick-next::before {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 25px;
  color: #37f !important;
}

.home-page-icon {
  object-fit: cover;
  width: 60px;
  height: 60px;
}

.slick-active button {
  background-color: #004d8c;
}

@media (max-width: 767px) {
  .block-slideshow__slide-content {
    margin-left: 10px;
  }

  .slick-dots {
    bottom: 0 !important;
  }

  .slick-arrow {
    bottom: 12px !important;
  }

  .divHomeFlexBlogPostsContainer {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .block-header__title {
    margin-bottom: 0;
    font-size: 16px;
  }

  .block-slideshow__slide-content {
    top: 0 !important;
    transform: translateY(0) !important;
  }

  .slick-dots {
    bottom: -6px !important;
  }

  .slick-arrow {
    bottom: 6px !important;
  }

  .divOfferCardsContainer .block-features__list {
    /* gap: 20px; */
  }
  /* .slick-current div div{
    height: 450px !important;
  }
  .block-slideshow__slide-content{
    height: 250px !important;
  } */
  
  .imgCarousel{
    height: 360px;
  }
  /* .block-slideshow__slide-content{
    margin: auto;
  } */
  .slick-slide div div{
    text-align: center;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
  }
  .block-slideshow__slide-text{
    margin-bottom: 0px;
    padding-top: 0px !important;
    position: relative;
    top: -10px;
  }
  .block-slideshow__slide-title{
    margin-bottom: 0px;
    /* padding-top: 40px !important; */
    /* padding-top: 20px !important; */
  }
  .block-slideshow__slide-button{
    /* padding-top: 60px !important; */
    padding-top: 7px !important;
  }
}

@media only screen and (min-width: 768px) {

  .block-slideshow__slide-content{
    top: 25% !important;
    transform: none !important;
    margin-left: 25px !important;
  }
}